import React from 'react';
import { Link } from 'react-router-dom';
import './CarouselContainer.css';

const CarouselContainer = ({ imageList }) => {
  // Duplicate the image list for seamless scrolling
  const duplicatedImageList = [...imageList, ...imageList];

  return (
    <div className="carousel-container">
      <div className="carousel">
        {duplicatedImageList.map((image, index) => (
          <Link to={`/image/${image.id}`} key={index} state={{ image }}>
            <img src={image.imageLink} alt={image.alt} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CarouselContainer;
