import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;


const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
        <AuthProvider>
            <App />
        </AuthProvider>
);