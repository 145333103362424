// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CarouselContainer.css */

.carousel-container {
  width: 80%;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 0 auto; 
}

.carousel {
  display: flex;
  width: calc(200px * 2 * 10 + 20px * (2 * 10)); 
  animation: scroll 40s linear infinite;
}

.carousel a {
  display: block;
  max-height: 40%;
}

.carousel img {
  width: 200px;
  height: auto;
  margin-right: 20px;
  border-radius: 10px;
  transition: transform 0.3s;
}

.carousel img:hover {
  transform: scale(1.1);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/carouselcontainer/CarouselContainer.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;EACE,UAAU;EACV,gBAAgB;EAChB,sBAAsB;EACtB,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,6CAA6C;EAC7C,qCAAqC;AACvC;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":["/* CarouselContainer.css */\n\n.carousel-container {\n  width: 80%;\n  overflow: hidden;\n  background-color: #fff;\n  border: 1px solid #ccc;\n  margin: 0 auto; \n}\n\n.carousel {\n  display: flex;\n  width: calc(200px * 2 * 10 + 20px * (2 * 10)); \n  animation: scroll 40s linear infinite;\n}\n\n.carousel a {\n  display: block;\n  max-height: 40%;\n}\n\n.carousel img {\n  width: 200px;\n  height: auto;\n  margin-right: 20px;\n  border-radius: 10px;\n  transition: transform 0.3s;\n}\n\n.carousel img:hover {\n  transform: scale(1.1);\n}\n\n@keyframes scroll {\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(-50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
