import axios from 'axios';
import { useAuth } from './../../../context/AuthContext';




export const useLoginAPI = () => {
    
    const { setIsLoggedIn } = useAuth();

    const login = async (userName, password, setErrors) => {

        try {
            const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/auth/login`,
                    {
                        userName: userName,
                        password: password
                    }
            );



            if (response.status === 200) {
                console.log("success");
                setIsLoggedIn(true);
                
            }
        } catch (error) {
            const errorMessages = {};

            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        errorMessages.form = "Invalid username or password.";
                        break;
                    case 404:
                        errorMessages.form = "User not found.";
                        break;
                    case 403:
                        errorMessages.form = "Forbidden";
                        console.log(error.response);
                        break;
                    default:
                        errorMessages.form = "An error occurred. Please try again later.";
                        break;
                }
            } else {
                console.error("Unknown error during login:", {error});
                errorMessages.form = "An unknown error occurred.";
            }
        }
        ;




    };

    return {login};
};



export default useLoginAPI;